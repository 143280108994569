/* Layout */

$layout-header-height: 60px;
$layout-footer-height: 30px;

$layout-normal-sidebar-min-width: 200px;
$layout-normal-sidebar-width: 20%;
$layout-collapsed-sidebar-fixed-width: 50px;

$layout-content-body-height: calc(100% - #{$layout-header-height} - #{$layout-footer-height});
$layout-normal-content-full-width: calc(100% - #{$layout-normal-sidebar-width});
$layout-collapsed-content-full-width: calc(100% - #{$layout-collapsed-sidebar-fixed-width});

$layout-left-content-min-width: 320px;
$layout-left-content-normal-width: 40%;

$layout-right-content-normal-width: calc(100% - #{$layout-left-content-normal-width});

/* Color */
$border-color: #c8c8c8;

