@use '@angular/material' as mat;
@import "./base/_base.scss";
@import "./base/_mixins.scss";

@import "./components/_button.scss";
@import "./components/_message-input-bar";

@import "./layouts/_layout.scss";

@import "./modules/_corporate-material.scss";
@import "./components/datetimepicker";

@import "./themes/_themes.scss";

// Bootstrap
@import "~bootstrap/dist/css/bootstrap.css";

// // Videogular2
// @import "~videogular2/fonts/videogular.css";

// Videogular2 to ngx-videogular
// @import "~@videogular/ngx-videogular/videogular.css";

// Notiejs
@import "~notie/dist/notie.min.css";

// Ngx-bootstrap
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

// ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";

// Font
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'), url('../../assets/fonts/Roboto-Regular.ttf') format('truetype'), url('../../assets/fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'NotoSansSC-Black';
  font-style: normal;
  font-weight: 900;
  src: local('NotoSansSC-Black'), url('../../assets/fonts/NotoSansSC-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), url('../../assets/fonts/Roboto-Black.ttf') format('truetype');
}

// Font Awesome
$fa-font-path: "node_modules/font-awesome/fonts";
// @import "node_modules/font-awesome/scss/font-awesome.scss";

// Material
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// Material 15
$my-palette: mat.$indigo-palette;
@include mat.core();
@include mat.legacy-core();

$my-primary: mat.define-palette($my-palette);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);
$my-info: mat.define-palette(mat.$grey-palette);


$fsd-theme: (
  50: #2978cc,
  100: #2978cc,
  200: #2978cc,
  300: #2978cc,
  400: #2978cc,
  500: #2978cc,
  600: #2978cc,
  700: #2978cc,
  800: #2978cc,
  900: #2978cc,
  // A100: #ff8a80,
  // A200: #ff5252,
  // A400: #ff1744,
  // A700: #d50000,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: #2978cc,
    600: white,
    700: white,
    800: white,
    900: white,
    // A100: #ff8a80,
    // A200: #ff5252,
    // A400: #ff1744,
    // A700: #d50000,
  )
);

$my-fsd: mat.define-palette($fsd-theme);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-fsd,
   warn: $my-warn
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($my-theme);
@include mat.all-legacy-component-themes($my-theme);

html, body {
  height: 100%; // fill the whole page
}

body {
  margin: 0;    //remove default body margin
  @include background-image-with-src();
  font-family: 'Roboto';
}

.base-bg {
  @include background-image-with-src();
}

.clickable {
  cursor: pointer;
}

.unclickable {
  cursor: not-allowed;
}

// Override notie.js CSS (show on top of material dialog)
.notie-container {
  z-index: 9999;
}

.account-dropdown-menu {
  display: flex !important;
  flex-direction: column;
}

.tn-dropdown-group {
  .tn-dropdown-button {
    width: 100%;
    @include clean-button();
  }
  .tn-dropdown-item {
    color: #333333;
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      @include item-highlight();
    }
  }
  .tn-dropdown-container {
    color: white;
    padding: 5px 10px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    justify-content: start;
    width: 100%;

    &:hover {
      @include item-highlight();
    }

    .add-account {
      min-height: 50px;
      display: inline-flex;
      align-items: center;
      color: black;
    }

    .active-background {
      // background-color: rgba(0, 0, 0, 0.075);
      background-color: #dadada;
    }
  }

  .tn-dropdown-info {
    color: #333333;
    padding: 5px 20px;
  }
}

.init-overlay {
  top: 0;
  left: 0;
  position: fixed;
  
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 9999;
  transition: opacity .8s ease-in-out;
  opacity: 1;
}

tn-root:not(:empty) + .init-overlay {
  display: none;
}

.force-display {
  display: flex !important;
}

.no-background-color {
  background-color: transparent !important;
}

.multiline-text-display {
  white-space: pre-line;
  word-wrap: break-word;
}

.preserve-spaces {
  white-space: pre-wrap !important;
}

.empty-option {
  min-height: 20px;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.tip {
  color: #909192;
  font-size: small;
}

.no-padding-dialog-panel {

  mat-dialog-container {
    padding: 0;
  }
}

.transparent-dialog-panel {

  mat-dialog-container {
    background: transparent;
    box-shadow: none;
  }
}

.text-red {
  color: red;
}

@-webkit-keyframes flashonce {
  0% {
    opacity: 1;
    background-color: none;
  }
  50% {
    opacity: 0;
    background-color: rgba(255,255,255,0.5);
  }
  100% {
    opacity: 1;
    background-color: none;
  }
}
@-moz-keyframes flashonce {
  0% {
    opacity: 1;
    background-color: none;
  }
  50% {
    opacity: 0;
    background-color: rgba(255,255,255,0.5);
  }
  100% {
    opacity: 1;
    background-color: none;
  }
}
@keyframes flashonce {
  0% {
    opacity: 1;
    background-color: none;
  }
  50% {
    opacity: 0;
    background-color: rgba(255,255,255,0.5);
  }
  100% {
    opacity: 1;
    background-color: none;
  }
}

.flash-once {
  animation: flashonce 1s ease-in-out 0s;
}

.hover-scale {
  &:hover {
    transform: scale(1.2);
  }
}

.overflow-hidden {
  overflow: hidden;
}

label {
  font-weight: normal !important;
}

