@import "../base/_mixins";
@import "../modules/_chat";

.message-send-bar {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;


  .message-option-bar {
    width: 100%;
    display: flex;
    padding: 5px 0;
    background-color: white;

    .option {
      cursor: pointer;
      padding: 0 5px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .message-content-bar {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .attachment-button {
    @include clean-button();
    min-width: 50px;

    .tn-dropdown-group {
      width: 100%;
    }

    .attachment-button-display {
      color: #333;
      font-size: 20px;
    }
  }
  
  input[type="file"] {
    display: none;
  }

  .sticker-button-wrapper {
    padding-left: 10px;
    width: 40px;
    height: 40px;

    .sticker-button {
      height: 100%;
      width: 100%;

      @include background-image-with-src();
      background-size: contain;

      &.sticker {
        background-image: url('../../../assets/images/general/sticker_toggle.png');
      }

      &.text {
        background-image: url('../../../assets/images/general/text_toggle.png');
      }
    }
  }

  .encrypted-msg-lock-wrapper {
    display: flex;
    justify-content: center;

    .encrypted-msg-lock {
      height: 30px;
      width: 30px;
    }
    .encrypted-msg-lock.on {
      @include background-image-by-src($chatroom-encryped-msg-lock-on);
    }
    .encrypted-msg-lock.off {
      @include background-image-by-src($chatroom-encryped-msg-lock-off);
    }
  }

  .input-area {
    width: 100%;
    height: 100%;
    padding: 5px 10px;

    textarea {
      height: 100%;
      padding: 0;
    }

    .textarea-file-over {
      background: rgba(0, 0, 0, 0.4);
      border: dotted 3px lightgrey;
    }
  }

  .send-button {
    cursor: pointer;
    min-width: 80px;
    text-align: center;
    padding: 5px 10px 5px 0;
  }

  .sticker-selector-wrapper {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    border-top: 1px solid lightgrey;

    tn-sticker {
      width: 100%;
    }
  }
}