// FSD Styles
// Created by Shan - 2018-11-14

$ttx-override: (
  "theme-color-primary": #082c4e,
  // "text-color-primary": #f6343f,
  // "text-color-primary": #2978cc,
  "border-color-primary": #666666,
  // Login page
  "base-bg-image-url": url("../../../assets/images/clients/fsd/login_bg.jpg"),
  "base-bg-color": #082c4e,

  // "login-logo-image-url": url("../../../assets/images/clients/fsd/login_logo.png"),
  "login-logo-image-url": url("../../../assets/images/clients/fsd/ttx_login_logo.svg"),
  "login-logo-height": 168px,
  "login-logo-width": 290px,

  // nav logo
  // "nav-logo-image-url": url("../../../assets/images/clients/fsd/nav_logo.png"),
  "nav-logo-image-url": url("../../../assets/images/clients/fsd/ttx_login_logo.svg"),
  "nav-logo-height": 53px,
  "nav-logo-width": 90px,

  // empty page logo
  // "empty-page-logo-image-url": url("../../../assets/images/clients/fsd/empty_page_logo.png"),
  "empty-page-logo-image-url": url("../../../assets/images/clients/fsd/ttx_login_logo.svg"),
  "empty-page-logo-height": 135px,
  "empty-page-logo-width": 160px,

  // chatroom
  // "chatroom-bg": url("/assets/images/clients/fsd/chatroom_bg_new.png"),
  "chatroom-bg": none,
  "chatroom-bg-color": #F4F3EE,
  "chatroom-msg-select-box": #4a4a4a,
  "chatroom-msg-sender-name-color": #4a4a4a,
  "chatroom-msg-read-color": #4a4a4a,
  "chatroom-msg-time-color": #4a4a4a,
  // "chatroom-msg-bg-right": #ed4847,
  "chatroom-msg-bubble-right-image-url": url("../../../assets/images/chatroom/chatroom_text_bubble_tip_right.png"),

  // buttons
  "button-primary-bg": #296db0,

  // Corporate material
  // "corporate-material-name-color": #f6343f
);

.theme-ttx {
  .multi-chat-room-panel {
    .filtered {
      background-color: #296db0;
    }
  }
}