@import "../base/_base.scss";
@import "../components/_page-title.scss";
@import '../base/_mixins.scss';


$layout-breakpoint-small: 768px;
$layout-breakpoint-medium: 1330px;

$layout-breakpoint-height: 700px;

.side-nav-tooltip {
  display: none;
}

.tn-webclient {
  height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  .tn-header {
    height: $layout-header-height;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-logo-exercise-container {
      display: flex;
      gap: 50px;
      align-items: center;

      .nav-logo-wrapper {
        display: flex;
        align-items: center;
      }

      .active-exercise-info {
        max-width: 700px;
        min-width: 500px;
        padding: 5px 10px;
        font-size: 16px;
        word-spacing: 2px;
        border: 1px solid white;
        border-radius: 5px;
      }
    }

  }

  .tn-content-body {
    height: $layout-content-body-height;
    display: flex;

    .tn-sidebar {
      min-width: $layout-normal-sidebar-min-width;
      width: $layout-normal-sidebar-width;

      &.multi-chatroom-mode, &.iframe-mode {
        min-width: $layout-collapsed-sidebar-fixed-width ;
        width: $layout-collapsed-sidebar-fixed-width ;

        .label-title {
          display: none !important;
        }

        .label-icon {
          height: 20px;
        }

        .nav-item-wrapper {
          display: flex !important;
          justify-content: center !important;
          flex-direction: column;

          .nav-item {
            position: relative !important; 

            .nav-count {
              position: absolute;
              height: 15px !important;
              width: 15px !important;
              font-size: 9px;
              left: 9px;
              top: 9px;
            }
          }

          .sub-navs-container {
            .sub-nav-item {
              padding-left: 0px !important;

              .sub-nav-label {
                position: relative !important;
                justify-content: center;

                i {
                  margin-right: 0px !important;
                }
                
                span {
                  display: none;
                }

                // .unapprove-msg-count {
                //   position: absolute;
                //   font-size: 9px;
                //   left: 9px;
                //   top: 9px;
                // }
              }
            }
          }

          .unapprove-msg-count {
            min-width: 15px;
            margin-right: 0px;
            width: 15px;
            height: 15px;
            position: absolute;
            font-size: 9px;
            left: 9px;
            top: 9px;
          }
    
          .nav-separator {
            width: 100%;
            margin: unset;
            height: 1px;
            border: none;
          }
        }
        
        
      }
      
      border-right: 1px solid $border-color;
      overflow-y: auto;
      overflow-x: hidden;

      transition: width .2s;


      &:hover {
        transition: width .2s;

        &.multi-chatroom-mode {
          min-width: $layout-normal-sidebar-min-width !important;
          width: $layout-normal-sidebar-width !important;

          .label-title {
            display: unset !important;
          }
  
          // .label-icon {
          //   height: 20px;
          // }
  
          .nav-item-wrapper {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column;
  
            .nav-item {
              position: relative !important; 
  
              .nav-count {
                position: absolute;
                height: 15px !important;
                width: 15px !important;
                font-size: 9px;
                left: 9px;
                top: 9px;
              }
            }

            .sub-navs-container {
              position: relative;
            
              .loading-masker {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 100;
            
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
            
                background-color: rgba(255, 255, 255, 0.5);
              }
            
              .sub-nav-item {
                display: flex;
                flex-direction: column;
                
                text-decoration: none;
                color: #777777;
                padding-left: 40px !important;
            
                .sub-nav-label {
                  justify-content: start;
                  display: flex;
                  align-items: center;
                  padding: 0px 15px;
                  height: 50px;
                  gap: 10px;

                  i {
                    margin-right: 10px; 
                    font-size: 16px;
                  }
            
                  .sub-nav-name {
                    display: block;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
            
                  // &-active {
                  //   @include item-highlight();
                  // }
            
                  // &:hover {
                  //   @include item-highlight();
                  // }
            
                  &.add-panel {
                    justify-content: flex-start !important;
                    border-bottom: none !important;
                    gap: 5px;

                    .add-green-icon {
                      display: flex;
                      justify-content: center;
                      align-items: center;
  
                      margin-right: 5px;
                    }

                    span {
                      display: block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
            
                &:not(:last-child) .sub-nav-label {
                  border-bottom: 1px solid #e5e5e5;
                }
              }
            }

          }
        }
      }
    }

    .tn-content-container {
      width: $layout-normal-content-full-width;
      overflow-y: hidden;

      &.multi-chatroom-mode-flex {
        flex: 1 1 0;
      }

      .tn-content {
        height: 100%;
        display: flex;
      }

      .tn-content-full {
        width: 100%;
        overflow-y: hidden;
      }

      .tn-content-left {
        width: $layout-left-content-normal-width;
        min-width: $layout-left-content-min-width;
        overflow-y: hidden;

        border-right: 1px solid $border-color;
      }

      .tn-content-right {
        width: $layout-right-content-normal-width;
        overflow-y: hidden;
      }
    }
  } // tn-content-wrapper

  .tn-footer {
    height: $layout-footer-height;
  }
}

.build-label, .system-label, .site-label, .topnav-label {
  font-size: x-large;
  margin: 0 10px;

  &.red {
    color: red;
  }
}

/* Medium Size */
@media only screen and (max-width: $layout-breakpoint-medium) {
  .side-nav-tooltip {
    display: block;
  }

  .tn-sidebar {
    min-width: $layout-collapsed-sidebar-fixed-width !important;
    width: $layout-collapsed-sidebar-fixed-width !important;

    .label-icon {
      height: 20px;
    }

    .show-label-icon {
      background-image: url('../../../assets/images/side_nav/default_nav_icon.png');
      background-size: cover;
      height: 20px;
      width: 20px;
    }

    .label-title {
      display: none !important;
    }
    .nav-item-wrapper {
      display: flex !important;
      justify-content: center !important;
      flex-direction: column;

      .nav-item {
        position: relative !important;

        .toggle-icon-container {
          display: none;
        }
        
        .nav-count {
          position: absolute;
          height: 15px !important;
          width: 15px !important;
          font-size: 9px;
          left: 9px;
          top: 9px;
        }
      }
      
      .sub-navs-container {
        .sub-nav-item {
          padding-left: 0px !important;

          .sub-nav-label {
            position: relative !important;
            justify-content: center !important;
            
            i {
              margin-right: 0px !important;
            }
            
            span {
              display: none;
            }

            // .unapprove-msg-count {
            //   position: absolute;
            //   font-size: 9px;
            //   left: 9px;
            //   top: 9px;
            // }
          }
        }
      }

      .unapprove-msg-count {
        min-width: 15px;
        margin-right: 0px;
        width: 15px;
        height: 15px;
        position: absolute;
        font-size: 9px;
        left: 9px;
        top: 9px;
      }

      .nav-separator {
        width: 100%;
        margin: unset;
        height: 1px;
        border: none;
      }
    }
  }

  .tn-content-container {
    width: $layout-collapsed-content-full-width !important;
  }
}

/* Small Size */
@media only screen and (max-width: $layout-breakpoint-small) {
  .current-focus-content {
    width: 100% !important;
    min-width: 100% !important;
    border: none !important;
  }
  
  .current-unfocus-content {
    display: none !important;
  }

  .chat-room-header, .chat-search-header {
    .page-title-wrapper {
      height: calc(#{$page-title-height} + 20px) !important;
    }
  }
}

/* Shorter Screen */
@media only screen and (max-height: $layout-breakpoint-height) {
  .chat-room-header, .chat-search-header {
    .page-title-wrapper {
      height: calc(#{$page-title-height} + 20px) !important;
    }
  }
}