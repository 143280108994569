@import "../components/_avatar.scss";
@import "../components/_page-title.scss";
@import "../components/_search-bar.scss";
@import "../components/_list.scss";
@import "../base/_base.scss";

/* Chat */

// Chat list
$chat-list-search-bar-wrapper-height: $page-title-list-height;
$chat-list-height: $search-bar-list-height;

$chat-list-cateogry-label-bg: #f8f8f8;
$chat-list-cateogry-label-text: #666666;

$chat-list-avatar-size: $avatar-normal-size;
$chat-list-avatar-addon-size: $avatar-addon-size;
$chat-list-last-msg-time-color: #b3b3b3;

$chat-list-col2-width: 100px;
$chat-list-col1-width: calc(100% - #{$chat-list-col2-width});

$chat-list-announcement-icon-image-url: '../../../assets/images/general/icon_announcement.png';


// Chat room
$chatroom-header-height: calc(#{$page-title-height} + #{$search-bar-full-height});
// $chatroom-search-message-list-height: calc(100% - #{$search-bar-full-height} - (#{$page-title-height} + 20px));
$chatroom-search-message-list-height: calc(100% - #{$search-bar-full-height} - #{$chatroom-header-height});
$chatroom-footer-height: 60px;
$chatroom-content-height: calc(100% - #{$chatroom-header-height} - #{$chatroom-footer-height});

$chatroom-header-label-height: 30px;

$chatroom-msg-sender-avatar-size: $avatar-message-sender-size;
$chatroom-msg-time-color: #ffffff;
$chatroom-msg-read-color: #ffffff;
$chatroom-msg-sender-name-color: #ffffff;
$chatroom-msg-text-color-left: #000000;
$chatroom-msg-text-color-right: #ffffff;

$chatroom-msg-sending-text: #c0c0c0;
$chatroom-msg-sending-bg: #6b757f;
$chatroom-msg-sending-bubble: '../../../assets/images/chatroom/chatroom_text_bubble_tip_sending.png'; 

$chatroom-msg-left-whisper-text: #404040;
$chatroom-msg-left-whisper-bg: #c9acdb;
$chatroom-msg-left-whisper-bubble: '../../../assets/images/chatroom/chatroom_text_bubble_tip_left_whisper.png';
$chatroom-msg-right-whisper-text: #404040;
$chatroom-msg-right-whisper-bg: #c9acdb;
$chatroom-msg-right-whisper-bubble: '../../../assets/images/chatroom/chatroom_text_bubble_tip_right_whisper.png';

$chatroom-msg-left-important-bg: #ffa500;
$chatroom-msg-left-important-bubble: '../../../assets/images/chatroom/chatroom_text_bubble_tip_left_important.png';
$chatroom-msg-right-important-bg: #ffa500;
$chatroom-msg-right-important-bubble: '../../../assets/images/chatroom/chatroom_text_bubble_tip_right_important.png';

$chatroom-encryped-msg-lock-off: '../../../assets/images/chatroom/lock_off.png';
$chatroom-encryped-msg-lock-on: '../../../assets/images/chatroom/lock_on.png';
$chatroom-encryped-msg-lock-left-msg: '../../../assets/images/chatroom/lock_black.png';
$chatroom-encryped-msg-lock-right-msg: '../../../assets/images/chatroom/lock_white.png';

$chatroom-msg-single-tick: '../../../assets/images/chatroom/storked_single_tick.png';
$chatroom-msg-double-tick: '../../../assets/images/chatroom/stroked_double_tick.png';
$chatroom-msg-read-tick: '../../../assets/images/chatroom/stroked_double_bluetick.png';

// $chatroom-msg-single-tick: '../../../assets/images/chatroom/single_tick.png';
// $chatroom-msg-double-tick: '../../../assets/images/chatroom/double_tick.png';
// $chatroom-msg-read-tick: '../../../assets/images/chatroom/blue_tick.png';
$chatroom-msg-starred: '../../../assets/images/chatroom/chatroom_message_star_solid.png';
$chatroom-msg-unstar: '../../../assets/images/chatroom/chatroom_message_star_hollow.png';

$chatroom-attachment-doc-msg-icon: '../../../assets/icons/Property1=Doc.svg';
$chatroom-attachment-pdf-msg-icon: '../../../assets/icons/Property1=Pdf.svg';
$chatroom-attachment-excel-msg-icon: '../../../assets/icons/Property1=excel.svg';
$chatroom-attachment-ppt-msg-icon: '../../../assets/icons/Property1=ppt.svg';
$chatroom-attachment-unknown-file-msg-icon: '../../../assets/icons/Property1=unkown.svg';

$chatroom-attachment-doc-white-msg-icon: '../../../assets/icons/Property1=Doc_white.svg';
$chatroom-attachment-pdf-white-msg-icon: '../../../assets/icons/Property1=Pdf_white.svg';
$chatroom-attachment-excel-white-msg-icon: '../../../assets/icons/Property1=excel_white.svg';
$chatroom-attachment-ppt-white-msg-icon: '../../../assets/icons/Property1=ppt_white.svg';

$chatroom-global-vip-text: #E89418;
$chatroom-global-vip-icon: '../../../assets/images/chatroom/vip_global.svg';
$chatroom-individual-vip-text: #4CD75E;
$chatroom-individual-vip-icon: '../../../assets/images/chatroom/vip_individual.svg';

$message-content-max-width-large: 400px;
$message-content-max-width-medium: 260px;

$message-content-min-width: 40px;
$message-content-min-height: 25px;

$chat-search-unfiltered: '../../../assets/images/chatroom/black_filter.png';
$chat-search-filtered: '../../../assets/images/chatroom/white_filter.png';

@mixin chat-category-label() {
  display: flex;
  justify-content: space-between;

  height: 30px;
  padding: 5px 10px;
  border-bottom: 1px solid $border-color;
  
  background-color: $chat-list-cateogry-label-bg;
  
  color: $chat-list-cateogry-label-text;
  font-weight: bold;
}

.reset-style {
  color: #8696a0;

  display: inline-block;
  text-decoration: none;
  font-style: normal;
}

.del-content {
  &:not(.reset-style) {
    text-decoration: line-through;
  }
}

.em-content {
  &:not(.reset-style) {
    font-style: italic;
  }
}

.strong-content {
  &:not(.reset-style) {
    font-family: "Roboto Black", NotoSansSC-Black;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    font-synthesis: weight style;
  }
}